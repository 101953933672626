<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("my.group") }}
          </template>
          <template v-slot:toolbar>

          </template>
          <template v-slot:body>
            <table>
              <tr v-for="item in students" :key="item.studentId">
                <td>{{item.studentName}}</td>
              </tr>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
td>button{
  margin-right: 10px;
}
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "myGroup",
  components: {
    KTPortlet
  },
  data() {
    return {
      students: null,
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      ApiService.querySecured("my/group")
          .then(({ data }) => {
            this.students = data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
    }
  }
};
</script>
